<template>
    <section class="position-relative">
        <cargando v-if="loading" />
        <div class="row align-items-center mx-0 py-2 mb-3">
            <div class="br-50 ml-3 text-white bg-general3 d-middle-center cr-pointer" style="width:25px;height:25px;" @click="$router.push({name: 'admin.cupones.editar.clientes-especificos'})">
                <i class="icon-left f-12" />
            </div>
            <div class="col-auto text-general f-15">
                Buscar {{ datosBasicos.destinatario == 1 ? $config.cliente : $config.vendedor }}  por criterios
            </div>
            <div class="col-auto ml-auto">
                <div class="btn-general br-8 f-14 px-3" @click="anadirSeleccion()">
                    Añadir selección
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div :key="key" class="col-12">
                <tabla-general
                :data="tablaFiltered"
                alto="calc(100vh - 208px)"
                :mostrar-buscador="false"
                activar-seleccion-multiple
                :usar-paginacion="false"
                @seleccion="selccionarClientes"
                >
                    <template slot="cabecera-izquierda">
                        <div class="col-auto text-general f-18 f-600">
                            Resultados de usuarios
                        </div>
                        <div class="col-auto ml-auto text-general f-15 px-0">
                            Fecha de filtro
                        </div>
                        <div class="col-3">
                            <el-select v-model="valueFiltro" placeholder="Select" class="w-100" size="small" @change="listarClientes">
                                <el-option
                                v-for="item in rangoFechas"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                />
                            </el-select>
                        </div>
                        <div class="btn-action border">
                            <i class="icon-search f-18" />
                        </div>
                        <div class="col-auto text-general f-14 lh-14">
                            ítems afectados <br /> por el filtro <i class="icon-calendar-empty f-12 text-general-red" />
                        </div>
                    </template>
                    <template slot="adicionales-izquierda">
                        <el-table-column fixed="left" class-name="text-center text-general2 f-16" width="200">
                            <template slot="header" slot-scope="scope">
                                <p class="f-17 text-general">
                                    {{ datosBasicos.destinatario == 1 ? $config.cliente : $config.vendedor }}
                                </p>
                                <el-input v-model="cliente_filter" size="small" class="w-100 br-20" />
                            </template>
                            <template slot-scope="{row}">
                                {{ row.nombre }}
                            </template>
                        </el-table-column>
                        <el-table-column class-name="text-center text-general2 f-16" width="180">
                            <template slot="header" slot-scope="scope">
                                <p class="f-17 text-general">
                                    Ciudad
                                </p>
                                <el-input v-model="ciudad_filter" size="small" class="w-100 br-20" />
                            </template>
                            <template slot-scope="{row}">
                                {{ row.ciudad }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Genero" class-name="text-center text-general2 f-16" width="180">
                            <template slot="header" slot-scope="scope">
                                <p class="f-17 text-general">
                                    Género
                                </p>
                                <el-input v-model="genero_filter" size="small" class="w-100 br-20" />
                            </template>
                            <template slot-scope="{row}">
                                {{ row.genero }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Edad" class-name="text-center text-general2 f-16" width="200">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Edad
                                        </p>
                                        <div class="border py-2 d-middle justify-content-between bg-whitesmoke2 height-container">
                                            <span class="ml-2">
                                                {{ edad_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="edad_filter = null, accionFiltro()" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover
                                        placement="bottom"
                                        width="300"
                                        trigger="click"
                                        >
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minEdad" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxEdad" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('edad')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.edad }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Compras" class-name="text-center text-general2 f-16" width="200">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Compras <i class="icon-calendar-empty f-12 text-general-red" />
                                        </p>
                                        <div class="border height-container py-2 d-middle justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ cant_compras_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="cant_compras_filter = null, accionFiltro()" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minCantCompras" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxCantCompras" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('cant_compras')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.cant_compras }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Valor última compra" class-name="text-center text-general2 f-16" width="250">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Valor última compra
                                        </p>
                                        <div class="border height-container py-2 d-middle justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ valor_compra_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="valor_compra_filter = null, accionFiltro()" />
                                        </div>
                                    </div>
                                    <div class="col-auto pt-4 px-0">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minValorCompras" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxValorCompras" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('valor_ultima_compra')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ convertMoney(row.valor_ultima_compra, $usuario.idm_moneda) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Fecha última compra" class-name="text-center text-general2 f-16" width="250">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Fecha última compra
                                        </p>
                                        <div class="border height-container py-2 d-middle justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ fecha_compra_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="fecha_compra_filter = null, accionFiltro()" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <el-date-picker
                                                v-model="rangoFechaCompra"
                                                type="daterange"
                                                format="yyyy-MM-dd"
                                                value-format="yyyy-MM-dd"
                                                range-separator="-"
                                                start-placeholder="desde"
                                                end-placeholder="hasta"
                                                />
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('fecha_ultima_compra')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.fecha_ultima_compra || '--' }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Cedis" class-name="text-center text-general2 f-16" width="150">
                            <template slot="header" slot-scope="scope">
                                <p class="f-17 text-general">
                                    cedis
                                </p>
                                <el-input v-model="cedis_filter" size="small" class="w-100 br-20" />
                            </template>
                            <template slot-scope="{row}">
                                {{ row.cedis }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="cupon.destinatario === 1" label="Leechero principal" class-name="text-center text-general2 f-16" width="220">
                            <template slot="header" slot-scope="scope">
                                <p class="f-17 text-general">
                                    {{$config.vendedor}} principal
                                </p>
                                <el-input v-model="leechero_filter" size="small" class="w-100 br-20" />
                            </template>
                            <template slot-scope="{row}">
                                {{ row.lechero }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Antiguedad (Meses)" class-name="text-center text-general2 f-16" width="230">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Antiguedad (Meses)
                                        </p>
                                        <div class="border height-container py-2 d-middle justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ antiguedad_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="antiguedad_filter = null, accionFiltro()" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minAntiguedad" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxAntiguedad" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('antiguedad')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.antiguedad }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Calificaciones" class-name="text-center text-general2 f-16" width="200">
                            <template slot="header">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Calificaciones <i class="icon-calendar-empty text-general-red f-12" />
                                        </p>
                                        <div class="border height-container py-2 d-middle justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ calificaciones_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="calificaciones_filter = null, accionFiltro()" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minCalificaciones" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxCalificaciones" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('cant_calificaciones')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.cant_calificaciones }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Calificacion promedio" class-name="text-center text-general2 f-16" width="270">
                            <template slot="header">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Calificaciones promedio
                                        </p>
                                        <div class="border height-container py-2 d-middle justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ calificacion_promedio_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="calificacion_promedio_filter = null, accionFiltro()" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minPromedioCalificaciones" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxPromedioCalificaciones" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('calificacion_promedio_filter')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ redondearNumero(row.promedio_calificacion,1) || '--' }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Último acceso (Días)" class-name="text-center text-general2 f-16" width="250">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Último acceso (Días)
                                        </p>
                                        <div class="border height-container py-2 d-middle justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ ultimo_acceso_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="ultimo_acceso_filter = null, accionFiltro()" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minDiasAcceso" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxDiasAcceso" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('dias_ult_acceso')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.dias_ult_acceso || '--' }}
                            </template>
                        </el-table-column>
                        <el-table-column label="# de accesos" class-name="text-center text-general2 f-16" width="190">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            # de accesos <i class="icon-calendar-empty text-general-red f-12" />
                                        </p>
                                        <div class="border height-container py-2 d-middle justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ accesos_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="accesos_filter = null, accionFiltro()" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minCantAccesos" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxCantAccesos" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('cant_accesos')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.cant_accesos }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Uso de cupones" class-name="text-center text-general2 f-16" width="210">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Uso de cupones <i class="icon-calendar-empty text-general-red f-12" />
                                        </p>
                                        <div class="border height-container py-2 d-middle justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ cupones_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="cupones_filter = null, accionFiltro()" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minCantCupones" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxCantCupones" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('cant_cupones')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.cant_cupones }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Uso de bonos" class-name="text-center text-general2 f-16" width="210">
                            <template slot="header" slot-scope="scope">
                                <div class="d-flex">
                                    <div class="col px-0">
                                        <p class="f-17 text-general">
                                            Uso de bonos <i class="icon-calendar-empty text-general-red f-12" />
                                        </p>
                                        <div class="border height-container py-2 d-middle justify-content-between bg-whitesmoke2">
                                            <span class="ml-2">
                                                {{ bonos_filter }}
                                            </span>
                                            <i class="icon-cancel text-general-red cr-pointer" @click.stop="bonos_filter = null, accionFiltro()" />
                                        </div>
                                    </div>
                                    <div class="col-auto px-0 pt-4">
                                        <el-popover placement="bottom" width="300" trigger="click">
                                            <p class="text-general text-center my-2 f-15 f-600">
                                                Rango
                                            </p>
                                            <div class="row mx-0">
                                                <div class="col">
                                                    <el-input v-model="minCantBonos" placeholder="De" />
                                                </div>
                                                <div class="col">
                                                    <el-input v-model="maxCantBonos" placeholder="Hasta" />
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-content-center py-2">
                                                <div class="bg-general text-white f-17 px-4 py-1 br-10 cr-pointer" @click="accionFiltro('cant_bonos')">
                                                    Filtrar
                                                </div>
                                            </div>
                                            <i slot="reference" class="mt-2 icon-arrow-collapse-horizontal text-general2 f-25 cr-pointer" />
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                            <template slot-scope="{row}">
                                {{ row.cant_bonos }}
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-anadir-seleccionar ref="modalAnadirSeleccion" @update="listarClientes" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Cupones from '~/services/cupones/cupones'
export default {
    components: {
        modalAnadirSeleccionar: () => import('../partials/modalAnadirSeleccion')
    },
    data(){
        return {
            rangoFechas: [{
                value: 1,
                label: 'Ultima Semana'
            }, {
                value: 2,
                label: 'Ultimo Mes'
            }, {
                value: 3,
                label: 'Ultimos Tres Meses'
            }, {
                value: 4,
                label: 'Ultimo Año'
            }],
            valueFiltro: 1,
            tablaClientes:[],
            clientes:[],

            edad_filter: null,
            minEdad:null,
            maxEdad:null,

            cant_compras_filter: null,
            minCantCompras:null,
            maxCantCompras:null,

            valor_compra_filter:null,
            minValorCompras:null,
            maxValorCompras:null,

            fecha_compra_filter:null,
            rangoFechaCompra:[],

            antiguedad_filter:null,
            minAntiguedad:null,
            maxAntiguedad:null,

            calificaciones_filter:null,
            minCalificaciones:null,
            maxCalificaciones:null,

            calificacion_promedio_filter:null,
            minPromedioCalificaciones:null,
            maxPromedioCalificaciones:null,

            ultimo_acceso_filter:null,
            minDiasAcceso:null,
            maxDiasAcceso:null,

            accesos_filter:null,
            minCantAccesos:null,
            maxCantAccesos:null,

            cupones_filter:null,
            minCantCupones:null,
            maxCantCupones:null,

            bonos_filter:null,
            minCantBonos:null,
            maxCantBonos:null,

            cliente_filter: '',
            ciudad_filter: '',
            cedis_filter: '',
            leechero_filter: '',
            genero_filter: '',



            multipleSelection: [],

            tablaFiltered: [],
            loading:false,
            cupon:{},
            key:true,
        }
    },
    computed:{
        id_cupon(){
            return Number(this.$route.params.id_cupon);
        },
        ...mapGetters({
            datosBasicos: 'cupones/cupones/datosBasicos',
        })
    },
    watch: {
        cliente_filter(){
            this.delay(this.filterTabla)
        },
        ciudad_filter(){
            this.delay(this.filterTabla)
        },
        cedis_filter(){
            this.delay(this.filterTabla)
        },
        leechero_filter(){
            this.delay(this.filterTabla)
        },
        genero_filter(){
            this.delay(this.filterTabla)
        },
    },
    async mounted(){
        await this.listarClientes()

    },
    methods: {
        filterTabla(){
            this.key = !this.key

            if(this.cupon.destinatario === 1){
                this.tablaFiltered = this.tablaClientes.filter( data =>
                    data.nombre.toLowerCase().includes(this.cliente_filter.toLowerCase()) &&
                        data.ciudad.toLowerCase().includes(this.ciudad_filter.toLowerCase()) &&
                        data.genero.toLowerCase().includes(this.genero_filter.toLowerCase()) &&
                        (data.cedis && data.cedis.toLowerCase().includes(this.cedis_filter.toLowerCase())) &&
                        data.lechero ? data.lechero.toLowerCase().includes(this.leechero_filter.toLowerCase()) : ''
                )
            }
            if(this.cupon.destinatario === 2){
                this.tablaFiltered = this.tablaClientes.filter( data =>
                    data.nombre.toLowerCase().includes(this.cliente_filter.toLowerCase()) &&
                        data.ciudad.toLowerCase().includes(this.ciudad_filter.toLowerCase()) &&
                        data.genero.toLowerCase().includes(this.genero_filter.toLowerCase()) &&
                        (data.cedis && data.cedis.toLowerCase().includes(this.cedis_filter.toLowerCase()))

                )
            }
        },
        async listarClientes(){
            try {
                this.loading = true
                let params ={
                    rango:this.valueFiltro
                }
                const {data} = await Cupones.listarClientesNoAgregados(this.id_cupon,params)
                this.clientes = data.clientes
                this.cupon = data.cupon
                this.tablaClientes = _.cloneDeep(data.clientes)
                this.filterTabla()
            } catch (e){
                this.error_catch(e)
            }finally{
                this.loading =false
            }
        },
        selccionarClientes(seleccionados){
            //console.log(seleccionados);
            this.multipleSelection  = []
            _.forEach(seleccionados,(s)=>{
                this.multipleSelection.push(s.id_user)
            })
        },
        anadirSeleccion(){
            if(this.multipleSelection.length === 0){
                this.notificacion('Alerta',`Por favor seleccione almenos 1 ${this.$config.cliente}`,'info')
                return
            }
            this.$refs.modalAnadirSeleccion.toggle(this.multipleSelection);
        },
        accionFiltro(columna = ''){
            let clientesInicial = _.cloneDeep(this.clientes)

            if(columna === 'edad'){
                this.edad_filter = this.minEdad + '-' + this.maxEdad
            }
            if(columna === 'cant_compras'){
                this.cant_compras_filter = this.minCantCompras + '-' + this.maxCantCompras
            }
            if(columna === 'valor_ultima_compra'){
                this.valor_compra_filter = this.minValorCompras + '-' + this.maxValorCompras
            }
            if(columna === 'fecha_ultima_compra'){
                this.fecha_compra_filter = this.rangoFechaCompra[0] + '-' + this.rangoFechaCompra[1]
            }
            if(columna === 'antiguedad'){
                this.antiguedad_filter = this.minAntiguedad + '-' + this.maxAntiguedad
            }
            if(columna === 'cant_calificaciones'){
                this.calificaciones_filter = this.minCalificaciones + '-' + this.maxCalificaciones
            }
            if(columna === 'calificacion_promedio_filter'){
                this.calificacion_promedio_filter = this.minPromedioCalificaciones + '-' + this.maxPromedioCalificaciones
            }
            if(columna === 'dias_ult_acceso'){
                this.ultimo_acceso_filter = this.minDiasAcceso + '-' + this.maxDiasAcceso
            }
            if(columna === 'cant_accesos'){
                this.accesos_filter = this.minCantAccesos + '-' + this.maxCantAccesos
            }
            if(columna === 'cant_cupones'){
                this.cupones_filter = this.minCantCupones + '-' + this.maxCantCupones
            }
            if(columna === 'cant_bonos'){
                this.bonos_filter = this.minCantBonos + '-' + this.maxCantBonos
            }



            if(this.edad_filter != null){
                clientesInicial = clientesInicial.filter(o=>o.edad >= this.minEdad && o.edad <= this.maxEdad)
            }
            if(this.cant_compras_filter != null){
                clientesInicial = clientesInicial.filter(o=>o.cant_compras >= this.minCantCompras && o.cant_compras <= this.maxCantCompras)
            }
            if(this.valor_compra_filter != null){
                clientesInicial = clientesInicial.filter(o=>o.valor_ultima_compra >= this.minValorCompras && o.valor_ultima_compra <= this.maxValorCompras)
            }
            if(this.fecha_compra_filter != null){
                clientesInicial = clientesInicial.filter(o=>o.fecha_ultima_compra >= this.rangoFechaCompra[0] && o.fecha_ultima_compra <= this.rangoFechaCompra[1])
            }
            if(this.antiguedad_filter != null){
                clientesInicial = clientesInicial.filter(o=>o.antiguedad >= this.minAntiguedad && o.antiguedad <= this.maxAntiguedad)
            }
            if(this.calificaciones_filter != null){
                clientesInicial = clientesInicial.filter(o=>o.cant_calificaciones >= this.minCalificaciones && o.cant_calificaciones <= this.maxCalificaciones)
            }
            if(this.calificacion_promedio_filter != null){
                clientesInicial = clientesInicial.filter(o=>o.promedio_calificacion >= this.minPromedioCalificaciones && o.promedio_calificacion <= this.maxPromedioCalificaciones)
            }
            if(this.ultimo_acceso_filter != null){
                clientesInicial = clientesInicial.filter(o=>o.dias_ult_acceso >= this.minDiasAcceso && o.dias_ult_acceso <= this.maxDiasAcceso)
            }
            if(this.accesos_filter != null){
                clientesInicial = clientesInicial.filter(o=>o.cant_accesos >= this.minCantAccesos && o.cant_accesos <= this.maxCantAccesos)
            }
            if(this.cupones_filter != null){
                clientesInicial = clientesInicial.filter(o=>o.cant_cupones >= this.minCantCupones && o.cant_cupones <= this.maxCantCupones)
            }
            if(this.bonos_filter != null){
                clientesInicial = clientesInicial.filter(o=>o.cant_bonos >= this.minCantBonos && o.cant_bonos <= this.maxCantBonos)
            }

            this.tablaClientes = clientesInicial
            this.filterTabla()

        },
        limpiarFiltro(campo){

        }
    }
}
</script>
<style lang="scss" scoped>
.height-container{
    border-radius: 20px;
    height: 32px;
}
</style>
